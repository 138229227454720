






















import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { apiService } from '@/services/api.service';
import { internModule } from '@/store/modules/intern/intern.module';
import { notificationService } from '@/services/notification.service';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';

@Component({ components: { ValidationObserver } })
export default class EvaluationForm extends Vue {
  public evaluation = '';
  public submittingEvaluation = false;

  public async createEvaluation(): Promise<void> {
    try {
      this.submittingEvaluation = true;
      const newEvaluation = await apiService.createEvaluation({ evaluation: this.evaluation, internId: internModule.selectedInterns[0].id });
      this.evaluation = '';
      evaluationModule.addEvaluation(newEvaluation);
    } catch {
      notificationService.showGenericError();
      return;
    } finally {
      this.submittingEvaluation = false;
    }
  }
}
