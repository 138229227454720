











import { Component, Vue } from 'vue-property-decorator';
import { IApiEvaluation } from '@/store/modules/evaluations/contracts/evaluation.api-contract';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';

@Component({})
export default class EvaluationDetails extends Vue {
  public get evaluationDetails(): IApiEvaluation | null {
    return evaluationModule.evaluation;
  }
}
