






































import { Component, Vue } from 'vue-property-decorator';
import { IApiEvaluation } from '@/store/modules/evaluations/contracts/evaluation.api-contract';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';
import EvaluationSelectionTable from '@/components/evaluations/EvaluationSelectionTable.vue';
import EvaluationDetails from '@/components/evaluations/EvaluationDetails.vue';
import Choose from '@/assets/svg/choose.svg';
import NoData from '@/assets/svg/no-data.svg';

@Component({
  components: {
    EvaluationDetails,
    EvaluationSelectionTable,
    Choose,
    NoData,
  },
})
export default class evaluationList extends Vue {
  public get evaluations(): IApiEvaluation[] {
    return evaluationModule.evaluations.content;
  }

  public get evaluationsLoaded(): boolean {
    return evaluationModule.evaluations.loaded;
  }

  public get selectedEvaluation(): IApiEvaluation | null {
    return evaluationModule.evaluation;
  }
}
