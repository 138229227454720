




















import { Component, Vue } from 'vue-property-decorator';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';
import EvaluationForm from '@/components/evaluations/EvaluationForm.vue';
import EvaluationList from '@/components/evaluations/evaluationList.vue';
import { userModule } from '@/store/modules/user/user.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';

@Component({
  components: { EvaluationList, EvaluationForm },
})
export default class EvaluationsOverview extends Vue {

  public mounted(): void {
    evaluationModule.fetchEvaluations();
  }

  public get showEvaluationForm(): boolean {
    return userModule.role === AppRole.Mentor;
  }
}
