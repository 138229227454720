













































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { IApiEvaluation } from '@/store/modules/evaluations/contracts/evaluation.api-contract';
import { DataTableHeader } from 'vuetify';
import { userModule } from '@/store/modules/user/user.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { evaluationModule } from '@/store/modules/evaluations/evaluation.module';
import dayjs from 'dayjs';

@Component({})
export default class EvaluationSelectionTable extends Vue {
  @VModel()
  public selectedEvaluation!: IApiEvaluation | null;

  public onEvaluationSelected(evaluation: IApiEvaluation): void {
    evaluationModule.fetchEvaluation(evaluation.id);
  }

  @Prop()
  public evaluations!: IApiEvaluation[];

  @Prop()
  public evaluationsLoading!: boolean;

  public formatDate(date: string): string {
    return dayjs(date).format('L');
  }

  public get allEvaluationsLoaded(): boolean {
    return evaluationModule.allLoaded;
  }

  public onLastRowReached([entry]: IntersectionObserverEntry[]): void {
    if (entry.isIntersecting) {
      evaluationModule.fetchMoreEvaluations();
    }
  }

  public get showMentorColumn(): boolean {
    return userModule.role === AppRole.Intern;
  }

  public getCreatorName(evaluation: IApiEvaluation): string {
     return evaluation.createdBy
      ? `${evaluation.createdBy.firstName} ${evaluation.createdBy.lastName.charAt(0).toUpperCase()}.`
      : this.$t('user.deletedUser').toString();
  }

  public getRowClass(evaluation: IApiEvaluation): string {
    return evaluation.id === this.selectedEvaluation?.id
      ? 'evaluation-selection-table__row evaluation-selection-table__row--selected'
      : 'evaluation-selection-table__row';
  }
  
  public get headers(): DataTableHeader[] {
    const properties = ['date'];
    if (this.showMentorColumn) {
      properties.push('mentor');
    }
    return [
      ...properties.map((prop) => ({
        text: this.$t(`evaluation.evaluationTable.headers.${prop}`).toString(),
        value: prop,
        class: `table__header--${prop}`,
      })),
      {
        text: '',
        value: 'actions',
        width: '4rem',
      },
    ];
  }
}
